<template>
  <div>
    <a-row :gutter="[12, 8]">
      <a-col :span="24" style="width: 200px">
        <a-input v-model="productionOrderNumber" placeholder="生产工单号" allowClear />
      </a-col>
      <a-col :span="24" style="width: 200px">
        <a-input v-model="materialNumber" placeholder="物料编号" allowClear />
      </a-col>
      <a-col :span="24" style="width: 160px">
        <a-button @click="batchStockInModalVisible = true">批量半成品入库</a-button>
      </a-col>

      <a-col :span="24">
        <div style="height: 240px; width: 1600px; overflow-y: auto">
          <a-table
            rowKey="id"
            size="small"
            :loading="loading"
            :columns="columns"
            :data-source="tableData"
            :pagination="false"
            :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          >
            <div slot="number" slot-scope="value, item">
              <a @click="detial(item)">{{ value }}</a>
            </div>
            <div slot="action" slot-scope="value, item, index">
              <a-button-group size="small">
                <a-button @click="detial(item)">详情</a-button>
                <a-popconfirm v-if="item.status == 'in_progress'" title="确定关闭吗?" @confirm="close(item)">
                  <a-button type="primary">关闭工单</a-button>
                </a-popconfirm>
                <a-button v-if="item.status === 'in_progress'" type="primary" @click="openRequirement(item)">
                  物料需求
                </a-button>
                <a-button
                  v-if="item.status === 'in_progress' || item.status === 'completed' || item.status === 'closed'"
                  type="primary"
                  @click="openStockInModal(item)"
                >
                  半成品入库
                </a-button>
              </a-button-group>
            </div>
          </a-table>
        </div>
      </a-col>
    </a-row>

    <StockInModal v-model="stockInModalVisible" title="半成品入库" :item="targetItem" @update="update" />
    <RequirementModal v-model="requirementModalVisible" :productionOrderItem="targetItem" @cancel="list()" />
    <BatchStockInModal v-model="batchStockInModalVisible" :items="selectedRowItems" @create="list()" />
  </div>
</template>

<script>
import { productionOrderSubProductionOrders, productionOrderClose } from "@/api/production";

export default {
  components: {
    StockInModal: () => import("./StockInModal"),
    BatchStockInModal: () => import("./BatchStockInModal"),
    RequirementModal: () => import("@/components/RequirementModal"),
  },
  props: ["visible", "item"],
  data() {
    return {
      dataItems: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          fixed: "left",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "生产工单号",
          dataIndex: "number",
          width: 220,
          fixed: "left",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "类型",
          dataIndex: "type_display",
          width: 100,
        },
        {
          title: "状态",
          dataIndex: "status_display",
          width: 100,
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
        },
        {
          title: "计划数量",
          dataIndex: "total_quantity",
          width: 100,
        },
        {
          title: "完成数量",
          dataIndex: "quantity_produced",
          width: 100,
        },
        {
          title: "已成品入库",
          dataIndex: "stock_in_quantity",
          width: 100,
          customRender: (value, item) => {
            const totalStockInQuantity = item.production_stock_in_goods_items.reduce((acc, item) => {
              const quantity = item.stock_in_quantity || 0;
              return acc + quantity;
            }, 0);
            return totalStockInQuantity;
          },
        },
        {
          title: "计划开始时间",
          dataIndex: "planned_start_time",
          width: 180,
        },
        {
          title: "计划完成时间",
          dataIndex: "planned_end_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      targetItem: {},
      stockInModalVisible: false,
      requirementModalVisible: false,
      productionOrderNumber: "",
      materialNumber: "",
      loading: false,

      selectedRowKeys: [],
      selectedRowItems: [],
      batchStockInModalVisible: false,
    };
  },
  computed: {
    tableData() {
      return this.dataItems.filter((item) => {
        // 筛选 materialNumber
        if (this.materialNumber && this.materialNumber.length > 0) {
          if (!item.goods_number.toLowerCase().includes(this.materialNumber.toLowerCase())) {
            return false;
          }
        }

        // 筛选 productionOrderNumber
        if (this.productionOrderNumber && this.productionOrderNumber.length > 0) {
          if (!item.number.toLowerCase().includes(this.productionOrderNumber.toLowerCase())) {
            return false;
          }
        }

        // 如果两个筛选条件都满足，返回 true
        return true;
      });
    },
  },
  methods: {
    list() {
      this.loading = true;
      productionOrderSubProductionOrders({ id: this.item.id })
        .then((data) => {
          this.dataItems = data;
        })
        .finally(() => (this.loading = false));
    },
    detial(item) {
      this.$router.push({ path: "/production/detial", query: { id: item.id } });
    },
    close(item) {
      productionOrderClose({ id: item.id }).then((data) => {
        this.$message.success("关闭成功");
        this.dataItems = this.$functions.replaceItem(this.dataItems, data);
      });
    },
    openStockInModal(item) {
      this.targetItem = item;
      this.stockInModalVisible = true;
    },
    openRequirement(item) {
      this.targetItem = item;
      this.requirementModalVisible = true;
    },
    update(item) {
      this.dataItems = this.$functions.replaceItem(this.dataItems, item);
    },
    onSelectChange(selectedRowKeys, selectedRowItems) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowItems = selectedRowItems;
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
